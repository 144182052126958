$mq-grid: "screen and (min-width: 50rem)";
$mq-grid-max: "screen and (max-width: 50rem)";

:root {
  // font-size: clamp(1rem, calc(0.125rem + 1vw), 1.5rem);

  // Type families
  --font-primary: 'primo', "Adobe ITC Garamond", Garamond, Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif;
  --font-secondary: 'soehne', "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;

  // Type scale
  --t-huge: 2.5rem; // 77px
  --t-large: 2.25rem; // 51.333px
  --t-medium: 1.75rem; // 34.222px
  --t-base: 1.125rem;
  --t-subhead: calc(14rem / 16);

  @media #{$mq-grid} {
    --t-huge: 4.812rem; // 77px
    --t-large: calc(45rem / 16); // 51.333px
    --t-medium: calc(30rem / 16); // 34.222px
    --t-base: 1.25rem; // 20px
    --t-subhead: calc(15rem / 16);
  }

  // Type baseline
  --t-leading: 1.5217;
  --t-leading-em: calc(var(--t-leading) * 1em);
  --t-leading-rem: calc(var(--t-base) * var(--t-leading));
  --t-paragraph: calc(var(--t-leading-rem) * 0.75);

  // Colors
  --c-base: #595654;
  --c-base-rgb: 94, 94, 94;
  --c-emphasis: #111;
  --c-spot: #160f09;
  --c-spot-rgb: 22, 15, 9;
  --c-accent-darkest: #372516;
  --c-accent-darker: #483a2d;
  --c-accent-darker-rgb: 72, 58, 45;
  --c-accent-dark: #846952; /* AAA contrast against white backgrounds */
  --c-accent-dark-rgb: 132, 105, 82;
  --c-accent-light: #c9a183; /* AAA contrast against --c-spot */
  --c-accent-light-rgb: 201, 161, 131;
  --c-accent-lighter: #faf7f5;
  --c-accent-lighter-rgb: 250, 247, 245;
  --c-offwhite: #fff;
  --c-offwhite-rgb: 255, 255, 255;
  --c-selection-bg: var(--c-accent-dark);

  // Layout
  --t-measure: 60ch;
  --w-percent: 90%;
  --w-percent-unitless: 90;
  --w-content-max: 60rem;
  --w-content-max-unitless: 60;
  --w-max: 80rem;
  --w-max-unitless: 80;
  --g-column-gap: calc(var(--t-paragraph) * 2);
  --l-sidebar-width: 25%;
}

@font-face {
  font-family: 'soehne';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/assets/fonts/soehne-web-buch.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/soehne-web-buch.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/soehne-web-buch.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/soehne-web-buch.woff') format('woff') /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'primo';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/assets/fonts/PrimoSerif.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/PrimoSerif.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/PrimoSerif.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/PrimoSerif.woff') format('woff') /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'primo';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('/assets/fonts/PrimoSerif-Italic.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/PrimoSerif-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/PrimoSerif-Italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/PrimoSerif-Italic.woff') format('woff') /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'primo';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/assets/fonts/PrimoSerif-DemiBold.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/PrimoSerif-DemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/PrimoSerif-DemiBold.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/PrimoSerif-DemiBold.woff') format('woff') /* Pretty Modern Browsers */
}