.projects {
  --grid-gap: calc(var(--t-leading-rem) * 2);
  margin-block-end: var(--t-leading-rem);
}

.work-card {
  * {
    display: block;
  }

  .work-card-overlay {
    background: var(--c-spot);
    position: absolute;
    inline-size: 100%;
    block-size: 100%;
    opacity: 0;
  }

  .work-card-content {
    margin-block-start: 1rem;

    strong {
      font-weight: 400;
      color: var(--c-offwhite);
      font-size: var(--t-medium);
      line-height: 1.2;
      margin-block-end: 0.25rem;
    }

    span {
      font-family: var(--font-secondary);
      color: var(--c-accent-light);
      font-size: 1rem;
    }
  }

  a {
    text-decoration: none;
  }
}

.work-card-image {
  position: relative;
  transition: all 0.15s ease-out;
  transform: scale(1);
  
  a:hover & {
    transform: scale(1.01);
    opacity: 0.6;
  }

  a:active & {
    transition-duration: 80ms;
    transform: scale(1.01);
    opacity: 0.4;
  }
}