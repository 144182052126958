.footer-inner {
  --cluster-gap: var(--t-base);

  border-block-start: 1px solid var(--c-accent-dark);
  font-family: var(--font-secondary);
  font-size: 1rem;
  line-height: 1;
  padding: var(--t-base) 0;
  display: flex;
  justify-content: flex-end;
  margin-block-start: var(--t-leading-rem);

  .l-text-wrapper + .footer & {
    margin-block-start: calc(var(--t-leading-rem) * 2);
  }

  * {
    justify-content: center;
  }

  a, a:visited {
    color: var(--c-nav-text);
    text-decoration: none;
  }
}

.work-footer {
  padding: calc(var(--t-leading-rem) * 3) 0;
  margin: 0;
  background: var(--c-spot);

  h2 {
    color: var(--c-offwhite);
    margin-block: 0 var(--t-leading-rem);
    text-align: center;
  }

  &.work-footer-margin {
    margin-block-start: calc(var(--t-leading-rem) * 2);
  }


  + .footer {
    background: var(--c-spot);
    --c-nav-text: var(--c-offwhite);

    .footer-inner {
      margin-block-start: 0;
    }
  }

  .work-card {
    text-align: start;
  }
}