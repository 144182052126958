.big-blockquote {
  --bq-font-size: var(--t-large);
  --bq-leading: 1.1;
  --bq-top-padding: calc(var(--t-paragraph) * 2);

  text-align: center;
  text-wrap: balance;
  inline-size: var(--w-percent);
  max-inline-size: var(--w-max);
  margin: calc(var(--t-paragraph) * 3) auto;
  border: 0;
  padding: var(--bq-top-padding) 0 0;
  font-size: var(--bq-font-size);
  line-height: var(--bq-leading);
  color: var(--c-emphasis);
  position: relative;

  &:before {
    content: "";
    z-index: -1;
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    inline-size: 100%;
    // Cover top padding plus one line of text
    block-size: calc(var(--bq-font-size) * var(--bq-leading) + var(--bq-top-padding));
    background: url("/assets/images/quote.svg") center top no-repeat;
    background-size: contain;
  }

  @media screen and (min-width: 30rem) {
    --bq-font-size: var(--t-huge);
  }
}

figure:has(.big-blockquote) {
  .big-blockquote {
    margin-block-end: var(--t-leading-rem);
  }
  
  figcaption {
    margin-inline: auto;
    margin-block-end: calc(var(--t-leading-rem) * 2);
    text-align: center;
  }
}
