.card {
  --card-padding: var(--t-paragraph);
  --card-measure: calc(var(--t-measure) + calc(var(--card-padding) * 2));

  padding: var(--card-padding);
  background: var(--c-offwhite);
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(var(--c-accent-darker-rgb), 0.09);
  max-inline-size: var(--card-measure);

  [data-padding] & {
    margin: 0 auto;
  }

  @media #{$mq-grid} {
    --card-padding: calc(var(--t-paragraph) * 2);
  }

  p:last-child,
  ul:last-child li:last-child {
    margin-block-end: 0;
  }
}