.header {
  --c-nav-bg: var(--c-spot);
  --c-nav-bg-rgb: var(--c-spot-rgb);
  --c-nav-border: var(--c-accent-dark);
  --c-nav-text: var(--c-offwhite);
  --c-nav-subhead: var(--c-accent-light);
  --c-nav-h1: var(--c-offwhite);

  background: var(--c-nav-bg);
  text-align: center;
  color: var(--c-nav-text);

  h1 {
    max-inline-size: var(--w-max);
    margin-block: 0;
    margin-inline: auto;
    color: var(--c-nav-h1);
    text-wrap: balance;

    .case-study & {
      margin-block-end: var(--t-paragraph);
      margin-inline: 0;
    }
  }

  figcaption {
    margin-block-start: calc(var(--t-paragraph) / 2);
    margin-inline: auto;
  }
}

.header-copy {
  padding-block-end: calc(var(--t-paragraph) / 2);
  inline-size: var(--w-percent);
  margin-inline: auto;

  .case-study & {
    padding: calc(var(--t-leading-rem) * 2) 0;
    justify-content: space-between;
    --l-switcher-basis: 40rem;
    inline-size: var(--w-percent);
    margin: 0 auto;
    max-inline-size: var(--w-content-max);

    @media #{$mq-grid} {
      padding: Min(10vh, calc(var(--t-leading-rem) * 4)) 0;
    }
  }

  a, a:visited {
    color: var(--c-offwhite);
    text-decoration-color: rgba(var(--c-offwhite-rgb), 0.8);
  }
}

.header-image {
  background: linear-gradient(var(--c-nav-bg) 50%, var(--c-offwhite) 50%);
  margin: var(--t-paragraph) 0 calc(var(--t-paragraph) * 2);

  .header-image-inner {
    margin: 0 auto;
    inline-size: 90%;
    max-inline-size: var(--w-max);
  }

  img {
    display: block;
    inline-size: 100%;
  }
}

.header .subhead {
  color: var(--c-nav-subhead);
  margin-block-end: 0.5rem;
  display: block;
  margin-inline: auto;

  .case-study & {
    text-align: start;
    margin-inline: 0;
  }
}

.header-copy-text {
  inline-size: 90%;
  .case-study & {
    text-align: start;
    max-inline-size: var(--t-measure);
  }
  
  margin-inline: auto;
}

.header-copy-intro {
  max-inline-size: calc(var(--t-measure) * 0.75);
  margin-block-end: var(--t-paragraph);
}

.header-skills {
  --l-stack-gap: 0;
  max-inline-size: max-content;
  color: var(--c-nav-subhead);
  font-family: var(--font-secondary);
  font-size: 1rem;
  list-style: none;
  text-align: start;
  padding: 0;

  li {
    padding: 0;
  }

  @media screen and (min-width: 44rem ) {
    text-align: end;
  }
}

@media screen and (min-width: 60rem) {
  .header-copy-intro {
    margin-block-end: 0;
  }
}

.header.no-image {
  --c-nav-border: var(--c-accent-dark);
  --c-nav-subhead: var(--c-accent-dark);
  --c-nav-text: var(--c-base);
  --c-nav-bg: var(--c-offwhite);
  --c-nav-bg-rgb: var(--c-offwhite-rgb);
  --c-nav-h1: var(--c-accent-darker);

  margin-block-end: 0;

  .header-copy {
    padding: calc(var(--t-leading-rem) * 1.5) 0 calc(var(--t-leading-rem) * 3);
  }
}

// Navbar

.navbar {
  padding: 1em 0 calc(var(--t-paragraph) * 2);
  position: relative;
  
  .case-study & {
    padding-block-end: 0;
  }
}

.navbar-inner {
  --l-switcher-gap: 0.5rem;
  --l-switcher-basis: 30rem;
  justify-content: space-between;
  border-block-end: 1px solid var(--c-nav-border);
  padding: calc(var(--t-paragraph) / 2) 0;

  * {
    line-height: 1;
    max-inline-size: fit-content;
  }

  
  a, a:visited {
    text-decoration: none;
    color: var(--c-nav-text);
  }
}

.navbar-links {
  --cluster-gap: 1em;

  a {
    font-family: var(--font-secondary);
    font-size: 1rem;
  }
}
