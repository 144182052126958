.gh-cmt {
  --border-color: var(--c-accent-dark);
  --header-color: var(--c-accent-lighter);
  --radius: 3px;
  --avatar-size: 3rem;
  --font-size: 0.875rem;
  --l-stack-gap: 0;
  
  position: relative;
  padding-inline-start: calc(var(--avatar-size) + 1rem);
  margin-block-start: calc(var(--t-paragraph) * 2);
  margin-block-end: var(--t-paragraph);
  font-size: var(--font-size);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-align: start;

  h3 {
    font-size: var(--t-base);
    font-weight: 700;
    margin: var(--font-size) 0 calc(var(--font-size) * 0.75);
  }

  li {
    margin-block-end: 0.5rem;
  }

  img {
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    border-radius: 3px;
    block-size: var(--avatar-size);
    inline-size: var(--avatar-size);
  }

  p {
    margin-block-end: 0.75rem;

    &:last-child {
      margin: 0;
    }
  }
}

.gh-cmt-content > * {
  padding: 0.5em 0.75em;
  border: 1px solid var(--border-color);
}

.gh-cmt-header {
  background: var(--header-color);
  border-radius: var(--radius) var(--radius) 0 0;
  position: relative;
  color: var(--c-accent-dark);
  &:before,
  &:after {
    content: "";
    position: absolute;
    inset-block-start: 50%;
    margin-block-start: -5.5px;
    inline-size: 0;
    block-size: 0;
    border-style: solid;
    border-width: 5px 6px 5px 0;
  }

  &:before {
    z-index: 2;
    border-color: transparent var(--header-color) transparent transparent;
    inset-inline-start: -5px
  }

  &:after {
    z-index: 1;
    border-color: transparent var(--border-color) transparent transparent;
    inset-inline-start: -7px;
  }

  span {
    font-weight: 700;
  }
}

.gh-cmt-placeholder {
  background: url('https://jrubenoff.imgix.net/projects/shortcuts/illos/github/placeholder.svg') no-repeat;
  opacity: 0.5;
  inline-size: 100%;
  block-size: 2.5rem;
  margin: 0.25rem 0;
}

.gh-cmt-body {
  border-block-start: 0;
  border-radius: 0 0 var(--radius) var(--radius);
  background: #fff;
  color: #474d54;
  padding: 1em;
}