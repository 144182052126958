$margin-basic: 1.5rem;

.l-wrapper,
.l-text-wrapper {
  inline-size: var(--w-percent);
  margin: 0 auto;
}

.l-wrapper {
  max-inline-size: var(--w-max);
}

.l-text-wrapper {
  max-inline-size: var(--t-measure);
}

.l-frame {
  --l-frame-width: 16;
  --l-frame-height: 9;
  aspect-ratio: var(--l-frame-width) / var(--l-frame-height);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  > img,
  > iframe,
  > mux-player {
    inline-size: 100%;
    block-size: 100%;
  }
}

.l-grid {
  --grid-gap: var(--g-column-gap);
  display: grid;
  flex-wrap: wrap;
  gap: var(--grid-gap);
  
  @supports (width: Min(30rem, 100%)) {
      grid-template-columns: repeat(auto-fit, minmax(Min(calc(var(--t-measure) / 2), 100%), 1fr));
  }
}

.l-switcher {
  display: flex;
  flex-wrap: wrap;
  margin: $margin-basic;

  @supports (gap: var(--cluster-gap)) {
    margin: unset;
    gap: var(--l-switcher-gap, var(--g-column-gap));
  }

  > * {
    flex-grow: 1;
    flex-basis: calc((var(--l-switcher-basis, var(--t-measure)) - 100%) * 999);
  }

  > :nth-last-child(n+ 3),
  > :nth-last-child(n+ 3) ~ * {
    flex-basis: 100%;
  }
}

.l-stack-basic,
.l-stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.l-stack-basic {
  > * {
    margin-block: 0;

    + * {
      margin-top: 1.5rem;

      @supports (margin-block-start: var(--l-stack-gap)) {
        margin-block-start: var(--l-stack-gap, var(--t-paragraph));
      }
    }
  } 
}


.l-stack {
  * {
    margin-block: 0;

    + * {
      margin-top: $margin-basic;

      @supports (margin-block-start: var(--l-stack-gap)) {
        margin-top: unset;
        margin-block-start: var(--l-stack-gap, var(--t-paragraph));
      }
    }
  } 
}

.l-sidebar {
  --l-sidebar-content-width: 61.8%;
  --l-sidebar-width: 20rem;
  --l-sidebar-gap: var(--g-column-gap);

  max-inline-size: var(--w-max);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--l-sidebar-gap);

  > :first-child {
    flex-basis: var(--l-sidebar-width);
    flex-grow: 1;

    h1 {
      margin-block-start: 0;
    }
  }
  
  > :last-child {
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: var(--l-sidebar-content-width);
  }
}

.dark {
  --c-base: #fff;
  --c-emphasis: #fff;
  --c-base-rgb: 255,255,255;
  --c-link-text: var(--c-base);
  --c-link-text-rgb: var(--c-base-rgb);

  background: var(--c-spot);
}

.l-cluster {
  display: flex;
  flex-wrap: wrap;
  margin-left: $margin-basic;

  @supports (gap: var(--cluster-gap)) {
    margin-left: unset;
    gap: var(--cluster-gap, var(--g-column-gap));
  }
}