.instagram {
  border: 1px solid var(--c-accent-dark);
  border-radius: 3px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.06);
  margin: 0 auto;
  overflow: hidden;
  background: var(--c-spot);
  max-inline-size: var(--t-measure);

  * {
    position: relative;
    display: block;
  }

  .instagram-header,
  .instagram-footer {
    z-index: 2;
    padding: 0.5rem 0;
    background: var(--c-accent-lighter);
  }

  circle, path, rect {
    fill: var(--c-accent-dark);
  }

  .instagram-play,
  .instagram img {
    opacity: 0.8;
    transition: all ease-out;
  }
  
  .instagram-play {
    transition-duration: 200ms;
    transform-origin: 0 0;
    position: absolute;
    z-index: 2;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: scale(0.4) translate(-50%, -50%);
    opacity: 0.8;

    path {
      fill: var(--c-offwhite);
    }
  }

  img {
    transition-duration: 150ms;
  }

  a:hover,
  a:focus {
    .instagram-play {
      opacity: 0.9;
    }

    img {
      transform: scale(1.01);
      opacity: 1;
    }

    .instagram-play {
      transform: scale(0.55) translate(-50%, -50%);
    }
  }

  a:active {
    .instagram-play,
    img {
      transition-duration: 80ms;
      opacity: 0.95;
    }

    img {
      transform: scale(1.00);
    }

    .instagram-play {
      transform: scale(0.52) translate(-50%, -50%);
    }
  }
}

.instagram-header {
  aspect-ratio: 573 / 48;
}

.instagram-footer {
  aspect-ratio: 573 / 50;
}