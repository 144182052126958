.media {
  --shadow-color: rgba(0, 0, 0, 0.4);

  background: var(--c-accent-darkest);
  margin: var(--t-leading-rem) 0;
  padding: 0;

  h2 {
    margin: unset;
  }

  img,
  iframe,
  mux-player,
  .card,
  .media-inner > .illo-card {
    display: block;
    margin: 0 auto;
  }

  + .media {
    margin-block-start: calc(var(--t-leading-rem) * -2);
  }

  &[data-padding] {
    padding: calc(var(--t-leading-rem) * 2) 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &[data-background="dark"] {
    figcaption {
      color: var(--c-accent-light);
      text-align: center;
    }
  }

  &[data-background="light"] {
    background: var(--c-accent-lighter);
    --shadow-color: rgba(var(--c-accent-darker-rgb), 0.09);
    
    figcaption {
      color: var(--c-accent-darker);
    }
  }

  &[data-background="shortcut-blue"] {
    background: linear-gradient(#3475b2, darken(#3475b2, 7%));
    --shadow-color: rgba(0, 0, 0, 0.09);
  }

  &[data-background="white"] {
    background: #fff;
  }

  &[data-position="first"] {
    margin-block-start: 0;
  }

  &[data-position="last"] {
    margin-block-end: 0;
  }

  // Always place [data-no-shadow] at the
  // end of the stylesheet

  &[data-no-shadow] {
    --shadow-color: transparent;
  }

  @media #{$mq-grid} {
    margin: calc(var(--t-leading-rem) * 2) 0;
  }
}

.media:not([data-bleed]) .media-inner {
  inline-size: 90%;
  max-inline-size: var(--w-max);
  margin: 0 auto;

  > img,
  > iframe,
  > mux-player,
  > .l-stack img,
  > .l-stack .l-frame {
    box-shadow: 0 4px 10px var(--shadow-color);
    inline-size: auto;
    block-size: auto;
    max-inline-size: Min(90%, var(--w-max));
    max-block-size: 85vh;
  }

  > .l-stack .l-frame {
    inline-size: 100%;
    block-size: 100%;
    align-self: center;
  }
}

.screencast .l-frame {
  --l-frame-width: 1208;
  --l-frame-height: 664;
  background: #fff;
}

.principle .l-frame {
    --l-frame-width: 2106;
    --l-frame-height: 1110;
    background: #fff;
}

.liquify .l-wrapper {
  --l-frame-width: 3;
  --l-frame-height: 2;
  background: #c1c1c1;
}