// Homepage

.home {
  --photo-width: 15rem;

  .l-content {
    margin-block-end: calc(var(--t-leading-rem) * 2);
  }

  .l-sidebar {
    --l-sidebar-content-size: 20rem;
  }

  h1, a {
    color: var(--c-base);
  }

  h1 {
    display: flex;
    align-items: center;
    min-block-size: Min(var(--photo-width), 20vw);
    margin-block-end: calc(var(--t-leading-rem) * 2);
    text-wrap: balance;
  }
}

.home-self {
  img {
    max-inline-size: var(--photo-width);
    border-radius: 50%;
  }
}

.home-skill {
  max-inline-size: var(--t-measure);
  
  h3 {
    color: var(--c-accent-light);
    font-size: var(--t-base);
    // font-weight: 700;
    --l-stack-gap: 0;
  }
}