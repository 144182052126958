* {
  max-inline-size: var(--t-measure);
}

html,
body,
div,
header,
nav,
main,
footer,
figure,
iframe,
mux-player {
  max-inline-size: unset;
}

:root {
  font-size: clamp(100%, calc(100% + 0.25vw), 250%);
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: var(--font-primary);
  font-size: var(--t-base);
  line-height: var(--t-leading);
  color: var(--c-base);
  letter-spacing: -0.005em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--c-offwhite);
}

b, strong {
  font-weight: 700;
}

h1, h2, h3, h4, h5, h6, ul, ol,
.h1, .h2, .h3, .h4, .h5, .h6 {
  padding: 0;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
  color: var(--c-emphasis);
  margin: 0 0 var(--t-paragraph);
}

h1, .h1,
h2, .h2,
h3, .h3 {
  --l-stack-gap: calc(var(--t-paragraph) * 3);
}

h1, .h1 {
  font-size: var(--t-huge);
  line-height: 1.1;
}

h2, .h2 {
  font-size: var(--t-large);
  line-height: 1.2;
}

h3, .h3,
h4, .h4 {
  line-height: 1.2;
}

h3, .h3 {  
  font-size: var(--t-medium);
  + * {
    --l-stack-gap: calc(var(--t-leading-rem) * 0.5);
  }
}

h4, .h4 {
  font-size: var(--t-base);
  font-weight: 700;
}

h5, .h5, dt {
  font-size: 0.95em;
  line-height: 1.1;
  font-variant: all-small-caps;
  letter-spacing: 0.05em;
}

.subhead {
  font-family: var(--font-secondary);
  font-size: var(--t-subhead);
  word-spacing: 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--c-accent-light);
}

dt {
  margin-block-end: 0.25em;
}

blockquote, p, figure {
  margin: 0;
  padding: 0;
}

blockquote {
  border-inline-start: 0.5rem solid var(--c-accent-light);
  padding-inline-start: var(--t-paragraph);
}

ul,
ol {
  padding-inline-start: 1rem;

  li {
    padding-inline-start: 0.5rem;
  }
}


hr {
  border: 0;
  border-block-start: 1px solid var(--c-accent-light);
  color: inherit;
  margin-block-end: var(--hr-margin);
  inline-size: 100%;
}

hr, hr + * {
  --hr-margin: calc(var(--t-leading-rem) * 2);
  --l-stack-gap: var(--hr-margin);
}

a {
  text-decoration-color: rgba(var(--c-link-text-rgb, var(--c-accent-dark-rgb)), 0.6);
  color: var(--c-link-text, var(--c-accent-dark));
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
  text-decoration-skip-ink: all;
}

time {
  font-variant-numeric: tabular-nums;
  letter-spacing: 0.02em;
}

figcaption {
  color: var(--c-base);
  font-size: var(--t-subhead);
  font-family: var(--font-secondary);
}

// Circle numbers in heading elements

.h-num {
  display: block;
  margin-block-end: 0.5rem;
}

.h-num-circle {
  border: 1px solid var(--c-emphasis);
  border-radius: 50%;
  inline-size: var(--t-leading-em);
  block-size: var(--t-leading-em);
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}