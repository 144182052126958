.value-head {
  margin-block-start: calc(var(--t-leading-rem) * 2);
  margin-block-end: calc(var(--t-paragraph) * 2);
  text-align: center;
}

.value-wrapper {
  justify-content: center;
}

.value-wrapper .value {
  flex-basis: Min(calc(var(--t-measure) * 0.75), 100%);
  align-self: stretch;
  margin: 0;
}

.value h3 {
  text-align: center;
}

.value-icon {
  margin: 0 auto calc(var(--t-leading-rem) * 0.5);

  svg {
    inline-size: 5rem;
    block-size: 5rem;
    display: block;
    margin: 0 auto;
  }

  path {
    stroke: var(--c-accent-darker);
  }
}