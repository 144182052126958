// Blog

.post-list {
  --l-stack-gap: calc(var(--t-paragraph) * 3);
  margin-block: var(--t-leading-rem) var(--t-paragraph);
}

.post {
  align-self: stretch;
  max-inline-size: calc(var(--t-measure) * 1.25);
  --l-switcher-gap: var(--t-paragraph);

  > * {
    display: block;
  }

  h2 {
    font-size: var(--t-medium);
    margin-block-end: 0.5rem;
  }

  p {
    color: var(--c-accent-light);
    font-family: var(--font-secondary);
    font-size: 1rem;
    max-inline-size: var(--t-measure);
  }

}

.post-meta {
  font-size: var(--t-subhead);
  max-inline-size: max-content;
  position: relative;
  inset-block-start: 0.5rem;
}

.source,
.footnotes {
  font-family: var(--font-secondary);
  font-size: var(--t-subhead);
  margin: calc(var(--t-paragraph) * 2) 0;
}