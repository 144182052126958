@media print {
  h1, h2, h3, .home-link {
    color: var(--c-emphasis) !important;
  }

  :root {
    --c-accent-light: var(--c-base);
    --c-accent-dark: var(--c-base);
    --c-accent-darker: var(--c-base);
  }

  footer, .navbar-links {
    display: none !important;
  }

  h2 {
    page-break-before: always;
  }

  .l-text-wrapper a:after {
    content: " [" attr(href) "] ";
  }

  .intro {
    font-size: var(--t-base);
  }
}